import React,{useState, useEffect} from 'react';
import { useQuery } from 'react-query'
import { AiOutlineFilePdf } from 'react-icons/ai';
import { PDFExport } from '@progress/kendo-react-pdf';
import background from './background1.jpg';
import './App.css';


function App() {

  
  const [state, setstate] = useState({})
  const [form, setForm] = useState({})
  const { isLoading, error, data } = useQuery('repoData', () =>
  fetch('https://api.surveys.xane.ai/dev/pdf', { headers: {
    'x-auth': '55de8415908e4f44baee0519c89552e1',
    'x-origin': '1',
    'x-version': '1'
  }}).then(res => 
    res.json()
  )
  // const { isLoading, error, data } = useQuery('repoData', () =>
  // fetch('https://60dd6037878c890017fa27f3.mockapi.io/form').then(res => 
  //   res.json()
  // )
)
// var knex = require('knex')({
//   client: 'mysql',
//   connection: {
//     host : '127.0.0.1',
//     port : 3306,
//     user : 'your_database_user',
//     password : 'your_database_password',
//     database : 'myapp_test'
//   }
// });
  useEffect(() => {
    if(!isLoading && data.data.responses[0]){
    var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;
        setstate({
          name: data.data.responses[0] ? data.data.responses[0].answer: "",
          date: data.data.responses[0] ? today : "",
          signature: data.data.responses[0] ? "" : "",
          department: data.data.responses[0]? data.data.responses[3].answer : "Engineering",
          address: data.data.responses[0] ? data.data.responses[5].answer : "",
          // address: data.data.responses[0] ? data.data.responses.find(element => element.id == 910).answer : "",
          address2: data.data.responses[0] ? "":"",
          address3: data.data.responses[0] ? "":"",
        })
      
    
     
    }
  
  }, [data, isLoading])

  if (isLoading) return <div className='spinner'></div>
 
  if (error) return 'An error has occurred: ' + error.message
 

 const exportPDF = () => {
    form.save();
}
  return (
    <div>
    {/* <button id="upload-btn" className="pulse" onClick={exportPDF}  > */}
    <button id="" className="" onClick={exportPDF}  >
              {/* <button class="fill">Fill In</button>
              <button class="pulse">Pulse</button>
              <button class="close">Close</button>
              <button class="raise">Raise</button>
              <button class="up">Fill Up</button>
              <button class="slide">Slide</button> */}
             Export PDF <AiOutlineFilePdf />
            </button>
    <div >
    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n<!--\nspan.cls_003{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}\ndiv.cls_003{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}\nspan.cls_004{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\ndiv.cls_004{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\nspan.cls_005{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:italic;text-decoration: none}\ndiv.cls_005{font-family:Arial,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:italic;text-decoration: none}\n-->\n" }} />

    <div  style={{position: 'absolute', left: '50%', marginLeft: '-306px', top: '0px', width: '612px', height: '792px', borderStyle: 'outset', overflow: 'hidden'}}>
   
    <PDFExport paperSize={'Letter'}
    fileName="form.pdf"
    title=""
    subject=""
    keywords=""
    ref={(r) => setForm(r)}>
    <div style={{
            height: 792,
            width: 612,
            padding: 'none',
            backgroundColor: 'white',
            boxShadow: '5px 5px 5px black',
            margin: 'auto',
            overflowX: 'hidden',
            overflowY: 'hidden'}}>
                
        </div>
      <div  style={{position: 'absolute', left: '0px', top: '0px'}}>
        <img src={background} alt="background" width={612} height={792} /></div>  
      <div style={{position: 'absolute', left: '231.65px', top: '198.50px'}} className="cls_003"><span className="cls_003">Conneqt Code of Conduct</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '249.89px'}} className="cls_004"><span className="cls_004">I acknowledge that I have received the Conneqt Code of Conduct.</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '275.69px'}} className="cls_004"><span className="cls_004">I have read the Conneqt Code of Conduct and I acknowledge that as a Conneqt</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '291.53px'}} className="cls_004"><span className="cls_004">employee, I am required to comply with the guidelines described therein and failure to</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '307.49px'}} className="cls_004"><span className="cls_004">do so may subject me to action as per my employment terms and relevant company</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '323.33px'}} className="cls_004"><span className="cls_004">policies.</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '349.25px'}} className="cls_004"><span className="cls_004">If I have a concern about a violation, or a potential violation of the Conneqt Code of</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '365.09px'}} className="cls_004"><span className="cls_004">Conduct, I understand that there are channels available to me in my company to report</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '380.93px'}} className="cls_004"><span className="cls_004">such concerns. By making use of these channels when necessary, I will play my part in</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '396.77px'}} className="cls_004"><span className="cls_004">maintaining the high ethical standards to which we hold ourselves.</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '448.51px'}} className="cls_004"><span className="cls_004">Signature: <span className = 'fields'>{state.signature}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '474.43px'}} className="cls_004"><span className="cls_004">Date: <span className = 'fields'>{state.date}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '500.35px'}} className="cls_004"><span className="cls_004">Name:</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '516.19px'}} className="cls_004"><span className="cls_004"><span className = 'fields padding100'>{state.name}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '541.99px'}} className="cls_004"><span className="cls_004">Department:<span className = 'fields'>{state.department}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '567.91px'}} className="cls_004"><span className="cls_004">Address: <span className = 'fields'>{state.address}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '593.74px'}} className="cls_005"><span className="cls_005"><span className = 'fields padding100'>{state.address2}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '619.66px'}} className="cls_005"><span className="cls_005"><span className = 'fields padding100'>{state.address3}</span></span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '671.38px'}} className="cls_005"><span className="cls_005">(Please submit this declaration to your Ethics Counsellor or the Human Resource</span></div>
      <div style={{position: 'absolute', left: '72.02px', top: '687.22px'}} className="cls_005"><span className="cls_005">department of your company.)</span></div>
</PDFExport>
    </div>
  </div>
  </div>
  );
}

export default App;